






















































































































































import Vue from 'vue';
import MainNav from '@/components/MainNav.vue';
import _, { noop, throttle } from 'underscore';
import store from '@/store';
import { imageIsLoaded, loadImage } from '@/lib/images';

type StoryImageName = 'hero' | 'apple_pick_wall_sit' | 'redbones_corner' | 'apple_pick_hand_hold' | 'redbones' | 'apple_pick_hand_hold_run';

let syncScrolled = noop;

export default Vue.extend({
  components: {
    MainNav,
  },

  data() {
    const webp = store.state.webpSupported;

    const storyImageSrc = {
      hero: webp ? require('@/assets/us/apple_pick_ladder_sit_q90_lossy.webp') : require('@/assets/us/apple_pick_ladder_sit_original.jpg'),
      apple_pick_wall_sit: webp ? require('@/assets/us/apple_pick_wall_sit_q90_lossy.webp') : require('@/assets/us/apple_pick_wall_sit_original.jpg'),
      redbones: webp ? require('@/assets/us/redbones_q90_lossy.webp') : require('@/assets/us/redbones_original.jpg'),
      redbones_corner: webp ? require('@/assets/us/redbones_corner_q90_lossy.webp') : require('@/assets/us/redbones_corner_original.jpg'),
      apple_pick_hand_hold_run: webp ? require('@/assets/us/apple_pick_hand_hold_run_q90_lossy.webp') : require('@/assets/us/apple_pick_hand_hold_run_original.jpg'),
      apple_pick_hand_hold: webp ? require('@/assets/us/apple_pick_hand_hold_q90_lossy.webp') : require('@/assets/us/apple_pick_hand_hold_original.jpg'),
    };

    const storyImageLoaded = {
      hero: imageIsLoaded(storyImageSrc.hero),
      apple_pick_wall_sit: imageIsLoaded(storyImageSrc.apple_pick_wall_sit),
      redbones: imageIsLoaded(storyImageSrc.redbones),
      redbones_corner: imageIsLoaded(storyImageSrc.redbones_corner),
      apple_pick_hand_hold_run: imageIsLoaded(storyImageSrc.apple_pick_hand_hold_run),
      apple_pick_hand_hold: imageIsLoaded(storyImageSrc.apple_pick_hand_hold),
    };

    return {
      scrolled: false,
      storyImageSrc,
      storyImageLoaded,
    };
  },

  computed: {
    storyImageStyles(): Record<StoryImageName, Partial<CSSStyleDeclaration>> {
      const styleFor = (name: StoryImageName): Partial<CSSStyleDeclaration> => {
        if (store.state.inPrerender || !this.storyImageLoaded[name]) return {};
        return {
          backgroundImage: `url('${this.storyImageSrc[name]}')`,
          filter: 'blur(0px)',
        };
      };

      return {
        hero: styleFor('hero'),
        apple_pick_wall_sit: styleFor('apple_pick_wall_sit'),
        redbones: styleFor('redbones'),
        redbones_corner: styleFor('redbones_corner'),
        apple_pick_hand_hold_run: styleFor('apple_pick_hand_hold_run'),
        apple_pick_hand_hold: styleFor('apple_pick_hand_hold'),
      };
    },
  },

  created(): void {
    this.loadStoryImages();
  },

  mounted(): void {
    syncScrolled = throttle(() => {
      this.scrolled = window.scrollY > 100;
    }, 300);

    syncScrolled();
    window.addEventListener('scroll', syncScrolled);
  },

  beforeDestroy(): void {
    window.removeEventListener('scroll', syncScrolled);
  },

  methods: {
    scrollDown(): void {
      if (this.scrolled) return;

      window.scrollTo({
        left: 0,
        top: window.innerHeight,
        behavior: 'smooth',
      });
    },

    loadStoryImages(): void {
      if (store.state.inPrerender) return;

      (Object.keys(this.storyImageSrc) as StoryImageName[]).forEach((name) => {
        if (this.storyImageLoaded[name]) return;
        loadImage(this.storyImageSrc[name]).then(() => {
          this.$set(this.storyImageLoaded, name, true);
        });
      });
    },
  },
});
